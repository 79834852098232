<template>
    <div class="container">
        <div class="top">
            <div class="top_title">巡检多模态分析系统</div>
            <div class="top_title_e"><u>宁夏交通执法查询系统</u></div>
        </div>
        <div class="main">
            <div class="main_btn">
                <div class="main_btn_l">巡检分析</div>
                <div class="main_btn_r">事件检索</div>
            </div>
            <div class="main_content">
                <div class="main_content_l">
                    <div class="main_content_l_top">
                        <input v-model="baseUrl" placeholder="请输入地址" class="main_content_l_top_input"/>
                        <div :class="tag?'main_content_l_top_btn':'main_content_l_top_btn_un'" @click="Start">上传检测</div>
                        <div :class="tag==false?'main_content_l_top_btn':'main_content_l_top_btn_un'" @click="Stop">停止检测</div>
                    </div>
                    <div class="main_content_l_img">
                        <img width="100%" height="100%" :src="'data:image/png;base64,'+imgData" alt="无图片"/>
                    </div>
                </div>
                <div class="main_content_r">
                    <div class="main_content_r_title">实时事件</div>
                    <el-checkbox-group v-model="checkList" @change="changeList" style="margin-right: auto">
                        <el-checkbox class="btn_w" label="常规" value="0" />
                        <el-checkbox class="btn_y" label="预警" value="1"/>
                        <el-checkbox class="btn_r" label="严重" value="2"/>
                    </el-checkbox-group>
                    <div class="scllor_container">
                        <div class="scllor_content">
                            <div :class="item.level=='0'?'content_index':item.level=='1'?'content_index_y':'content_index_r'" v-for="(item,index) in RealList" :key="index">
                                <div class="content_time">{{ item.time.split('T')[0]+' '+item.time.split('T')[1]+' ' }}{{item.detail}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import {getInffer,getImg,stopInffer,getEvent} from '@/utils/api'
let checkList = ref(["0","1","2"])
let list = ref([])
let RealList = ref([])
let tag = ref(true)
let Id = {since_id:-1}
let imgData = ref('')
let baseUrl = ref('')
const changeList = ()=>{
    console.log(checkList.value)
    RealList.value = list.value.filter(item=>{
        return checkList.value.includes(item.level)
    })
}
    let dr = {
        url: '',
        type: "rtsp"
    }
    let timer = ref(null)
    const Start=()=>{
        if(tag.value){
            dr.url = baseUrl.value
            getInffer(dr).then(res=>{
            console.log(res)
            timer.value = setInterval(()=>{
                getImg().then(result=>{
                    imgData.value = result.image
                })
                getEvent(Id).then(rel=>{
                    if(rel.events.length>0){
                        Id.since_id =  rel.events[rel.events.length-1].id
                        RealList.value.push({level:rel.events[0].level,detail:rel.events[0].detail,time:rel.events[0].timestamp})
                        list.value = JSON.parse(JSON.stringify(RealList.value))
                        console.log(list.value)
                    }
                })
            },100)
            })
            tag.value = false
        }
        
    }
    const Stop=()=>{
        if(!tag.value){
            stopInffer().then(res=>{
            console.log(res)
            clearInterval(timer.value)
            tag.value = true
            })
        }
    }
</script>

<style scoped>
    .contaier{
        width: 100%;
        height: 100vh;
        overflow-x: hidden;
        overflow-y: hidden;
        background-color: #3977fd;
    }
    .top{
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 10vh;
    }
    .top_title{
        font-size: 36px;
        color: #3977fd;
        font-weight: 700;
    }
    .top_title_e{
        font-size: 16px;
        margin-left: auto;
        color: rgb(81, 175, 252);
        margin-right: 10px;
    }
    .main{
        width: 99%;
        position: relative;
        overflow: hidden;
        height: 85vh;
    }
    .main_btn{
        position: absolute;
        top:0px;
        left:0;
        display: flex;
        color: #ffffff;
        justify-content: center;
        width: 100%;
    }
    .main_btn_l{
        width: 100px;
        height: 40px;
        background-color: #3977fd;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
    }
    .main_btn_r{
        width: 100px;
        height: 40px;
        background-color: #3977fd;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
    }
    .main_content{
        border: 1px solid #3977fd;
        height: 80vh;
        width: calc(100% - 30px);
        margin: 20px 0px 10px 10px;
        display: flex;
    }
    .main_content_l{
        width: 70%;
        padding: 10px;
    }
    .main_content_l_top{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top:20px
    }
    .main_content_l_top_input{
        width: 70%;
        height: 24px;
        font-size: 18px;
        background-color: #6d6d6d;
        color: #fff;
    }
    .main_content_l_top_btn{
        width: 14%;
        height: 36px;
        background-color: #4a81f7;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
    }
    .main_content_l_top_btn_un{
        width: 14%;
        height: 36px;
        background-color: #646464;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
    }
    .main_content_l_img{
        width: 100%;
        height: 70vh;
        border: 1px solid black;
        margin-top: 10px;
    }
    .main_content_r{
        width: 30%;
        display: flex;
        flex-direction: column;
    }
    .main_content_r_title{
        display: flex;
        color: #fff;
    }
    .btn_w{
        color: #fff !important;
    }
    .btn_y{
        color: #a5a200 !important;
    }
    .btn_r{
        color: #a50000 !important;
    }
    input::placeholder {
        color: #fff; /* 更改placeholder文字颜色 */
        }
    .scllor_container{
        width: 90%;
        height: 80vh;
        background-color: #3977fd;
        margin: 10px 5% 10px 5%;
        overflow: hidden;
    }
    .scllor_content{
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        margin-left: 17px;
    }
    .content_index{
        display: flex;
        font-size:16px;
        margin-top: 10px;
        color: #fff;
        align-items: center;
    }
    .content_index_y{
        display: flex;
        font-size:16px;
        margin-top: 10px;
        color: #a5a200;
        align-items: center;
    }
    .content_index_r{
        display: flex;
        font-size:16px;
        margin-top: 10px;
        color: #a50000;
        align-items: center;
    }
    .content_time{
        display: flex;
        align-items: center;
        height:22px;
    }
    .content_content{
        margin-left: 5px;
        display: flex;
        align-items: center;
        height:22px;
    }
</style>